import Vue from "vue";

export const requiredFields = () => [
  {
    key: 'id',
    label: Vue.prototype.$vDict('default_dashboard_widgets.field_id.text'),
    class: 'column-id',
    dataCy: 'country-id',
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('default_dashboard_widgets.field_country_id.text'),
    dataCy: 'countries',
  },
  {
    key: 'widgets',
    label: Vue.prototype.$vDict('default_dashboard_widgets.field_widgets.text'),
    dataCy: 'widgets',
  },
];
