import Vue from 'vue';
import { useProjectFields } from '@/entities/project/fields/useProjectFields';

export const useExportProjectsFields = () => {
  const projectFields = useProjectFields();

  return [
    {
      key: 'field_names',
      label: Vue.prototype.$vDict('projects.section_field_names.text'),
      icon: 'enter-text-2',
      dataCy: 'field-names',
      fields: [
        projectFields.project_nr,
        projectFields.project_title,
        projectFields.project_name,
        {
          key: 'project_url',
          label: Vue.prototype.$vDict('projects.field_project_url.text'),
          fieldset: ['id'],
          dataCy: 'project-url',
        },
      ],
    },
    {
      key: 'geography',
      label: Vue.prototype.$vDict('projects.section_geography.text'),
      icon: 'card-geography',
      dataCy: 'geography',
      fields: [
        projectFields.region_id,
        projectFields.district_id,
        projectFields.city,
        projectFields.postcode,
        projectFields.street,
        projectFields.property_designation,
      ],
    },
    {
      key: 'project_info',
      label: Vue.prototype.$vDict('projects.section_info.text'),
      icon: 'card-info',
      dataCy: 'project-info',
      fields: [
        projectFields.value,
        {
          key: 'category',
          label: Vue.prototype.$vDict('projects.field_category.text'),
          fieldset: ['cat_dev_type'],
          dataCy: 'category',
        },
        {
          key: 'construction_type',
          label: Vue.prototype.$vDict('projects.field_construction_type.text'),
          fieldset: ['cat_dev_type'],
          dataCy: 'construction-type',
        },
        {
          key: 'what_happens',
          fieldset: ['what_happens'],
          label: Vue.prototype.$vDict('projects.field_what_happens.text'),
          dataCy: 'what-happens',
        },
        {
          key: 'description',
          fieldset: ['description'],
          label: Vue.prototype.$vDict('projects.field_description.text'),
          dataCy: 'description',
        },
        projectFields.building_purpose,
        {
          key: 'plot_nr',
          label: Vue.prototype.$vDict('projects.field_plot_nr.text'),
          fieldset: ['project_area'],
          dataCy: 'plot_nr',
        },
        {
          key: 'last_source_of_info',
          label: Vue.prototype.$vDict('projects.field_last_source.text'),
          fieldset: ['publish_source_id'],
          dataCy: 'last-source-of-info',
        },
        {
          key: 'creation_date',
          label: Vue.prototype.$vDict('projects.field_creation_date.text'),
          fieldset: ['item_created_at'],
          dataCy: 'creation-date',
        },
        projectFields.latest_update_at,
      ],
    },
    {
      key: 'construction_details',
      label: Vue.prototype.$vDict('projects.section_construction_details.text'),
      icon: 'stage-construction',
      dataCy: 'construction-details',
      fields: [
        projectFields.start_date,
        projectFields.finish_date,
        projectFields.planning_approval_date,
        projectFields.stage_id,
        projectFields.planning_stage_id,
      ],
    },
    {
      key: 'properties',
      label: Vue.prototype.$vDict('projects.section_properties.text'),
      icon: 'properties',
      dataCy: 'properties',
      fields: [
        projectFields.buildings,
        projectFields.apartments,
        projectFields.floors,
        projectFields.materials,
        projectFields.gross_area,
        projectFields.gross_volume,
      ],
    },
    {
      key: 'own_fields',
      label: Vue.prototype.$vDict('projects.section_own_fields.text'),
      icon: 'enter-text',
      dataCy: 'own-fields',
      fields: [
        {
          key: 'project_status',
          label: Vue.prototype.$vDict('projects.field_project_status.text'),
          dataCy: 'project-status',
        },
        {
          key: 'responsible',
          label: Vue.prototype.$vDict('projects.field_responsible.text'),
          dataCy: 'responsible',
        },
        {
          key: 'notes',
          label: Vue.prototype.$vDict('projects.field_notes.text'),
          fieldset: ['id', 'source_id', 'title'],
          dataCy: 'notes',
        },
        {
          key: 'activities',
          label: Vue.prototype.$vDict('projects.field_activities.text'),
          fieldset: ['id', 'source_id', 'title'],
          dataCy: 'activities',
        },
      ],
    },
    {
      key: 'companies_on_project',
      label: Vue.prototype.$vDict('projects.section_companies_on_project.text'),
      icon: 'company',
      class: 'companies-on-project',
      fieldset: ['roles'],
      selectable: true,
      dataCy: 'companies-on-project',
      fields: [
        {
          key: 'company',
          label: Vue.prototype.$vDict('projects.section_company_fields.text'),
          fields: [
            {
              key: 'company_roles_org_number',
              label: Vue.prototype.$vDict('projects.field_company_roles_org_number.text'),
              dataCy: 'company-roles-org-number',
            },
            {
              key: 'company_roles_company_role',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_role.text'),
              dataCy: 'company-roles-company-role',
            },
            {
              key: 'company_roles_date',
              label: Vue.prototype.$vDict('projects.field_company_roles_assigned_date.text'),
              dataCy: 'company-roles-date',
            },
            {
              key: 'company_roles_post_street',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_post_street.text'),
              dataCy: 'company-roles-post-street',
            },
            {
              key: 'company_roles_country',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_country.text'),
              dataCy: 'company-roles-country',
            },
            {
              key: 'company_roles_postcode',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_postcode.text'),
              dataCy: 'company-roles-postcode',
            },
            {
              key: 'company_roles_post_city',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_post_city.text'),
              dataCy: 'company-roles-post-city',
            },
            {
              key: 'company_roles_phone',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_phone.text'),
              dataCy: 'company-roles-phone',
            },
            {
              key: 'company_roles_fax',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_fax.text'),
              dataCy: 'company-roles-fax',
            },
            {
              key: 'company_roles_email',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_email.text'),
              dataCy: 'company-roles-email',
            },
            {
              key: 'company_roles_website',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_website.text'),
              dataCy: 'company-roles-website',
            },
            {
              key: 'company_roles_company_url',
              label: Vue.prototype.$vDict('projects.field_company_roles_company_url.text'),
              dataCy: 'company-roles-company-url',
            },
          ],
        },
        {
          key: 'contact',
          label: Vue.prototype.$vDict('projects.section_contact_fields.text'),
          fields: [
            {
              key: 'company_roles_contact_nr',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_nr.text'),
              dataCy: 'company-roles-contact-nr',
            },
            {
              key: 'company_roles_contact_firstname',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_firstname.text'),
              dataCy: 'company-roles-contact-firstname',
            },
            {
              key: 'company_roles_contact_lastname',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_lastname.text'),
              dataCy: 'company-roles-contact-lastname',
            },
            {
              key: 'company_roles_contact_salutation',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_salutation.text'),
              dataCy: 'company-roles-contact-salutation',
            },
            {
              key: 'company_roles_contact_title',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_title.text'),
              dataCy: 'company-roles-contact-title',
            },
            {
              key: 'company_roles_contact_role',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_role.text'),
              dataCy: 'company-roles-contact-role',
            },
            {
              key: 'company_roles_contact_phone',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_phone.text'),
              dataCy: 'company-roles-contact-phone',
            },
            {
              key: 'company_roles_contact_email',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_email.text'),
              dataCy: 'company-roles-contact-email',
            },
            {
              key: 'company_roles_contact_url',
              label: Vue.prototype.$vDict('projects.field_company_roles_contact_url.text'),
              dataCy: 'company-roles-contact-url',
            },
          ],
        },
      ],
    },
    {
      key: 'custom_fields',
      label: Vue.prototype.$vDict('projects.section_custom_fields.text'),
      icon: 'internal',
      dataCy: 'custom-fields',
      hasDynamicFields: true,
    },
  ];
};

export const inheritFields = {
  company_roles_contact: ['company_roles_contact_firstname', 'company_roles_contact_lastname'],
  companies_on_project: ['company_roles_company_role'],
};
