/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
/*
  DashboardWidgetsList class
*/

import Vue from 'vue';
import { SEARCH_FILTER_TYPES } from '@/config/enums';
import { getDefaultDashboardWidgetsListConfig } from './config';
import { requiredFields } from './fields';
import { DefaultDashboardWidgets } from './DefaultDashboardWidgets';
import { getAllDashboardWidgets } from "@/config/dashboard-widgets";
import { getIdsFromArray } from "@/shared/utils";
import { getApiClients } from "@/api/repositories/apiClientsRepository";
import { loadCountriesComponents } from "@/views/system/default-dashboard-widgets/utils";
import EntityList from '@/shared/classes/list/EntityList';
import DefaultDashboardWidgetsTableView from './views/DefaultDashboardWidgetsTableView.vue';

export class DefaultDashboardWidgetsList extends EntityList {
  static entityClass = DefaultDashboardWidgets;

  constructor(listTypeId) {
    super(listTypeId, getDefaultDashboardWidgetsListConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = DefaultDashboardWidgetsTableView;

    this.selfClass = DefaultDashboardWidgetsList;
  }

  async loadDataForInitList() {
    const allWidgets = getAllDashboardWidgets();

    Vue.set(this.dataset, 'allWidgets', allWidgets);

    return this.dataset;
  }

  async loadDatasets(items = []) {
    const countryIds = getIdsFromArray(items, {
      idKey: 'country_id',
    });

    try {
      const countryComponents = await loadCountriesComponents(countryIds);
      this.updateDataset('countryComponents', countryComponents, 'country_id');
    } catch (error) {
      console.log(error);
    }
  }

  getListFiltersSetting(dataset = {}) {
    const allWidgets = dataset?.allWidgets || [];

    return [
      {
        key: 'country_id',
        dataCy: 'country',
        name: Vue.prototype.$vDict('default_dashboard_widgets.field_country_id.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'dashboard_widget_type_id',
        dataCy: 'widgets',
        name: Vue.prototype.$vDict('default_dashboard_widgets.field_widgets.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: allWidgets.map((widget) => ({
            id: widget.typeId,
            name: widget.title,
          })) || [],
        },
      },
    ];
  }
}
