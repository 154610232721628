<template>
  <base-table-view
    class="dashboard-widgets-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(country_id)="data">
      <span :data-cy="`country-${data.value.countryKey}`">
        {{ data.value.name}}  
      </span>
    </template>
    <template v-slot:cell(widgets)="data">
      <ul
        v-if="data.value && data.value.length > 0"
        class="sm-tags-list"
        data-cy="widgets-list"
      >
        <li
          v-for="(widget, index) in data.value"
          :key="index"
          class="sm-tags-list__item"
          :data-cy="widget.dataCy"
        >{{ widget.title }}</li>
      </ul>
    </template>
  </base-table-view> 
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'dashboard-widgets-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
};
</script>

<style lang="scss" scoped>

</style>
