import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('default_export_fieldsets.field_id.text'),
    class: 'column-id',
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('default_export_fieldsets.field_country_id.text'),
  },
  {
    key: 'list_type_id',
    label: Vue.prototype.$vDict('default_export_fieldsets.field_list_type_id.text'),
  },
  {
    key: 'fieldset',
    label: Vue.prototype.$vDict('default_export_fieldsets.field_fieldset.text'),
  },
];
