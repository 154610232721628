/*
  Subscription class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { COUNTRIES, ENTITY_TYPES, LIST_TYPES, PRODUCTS, SOURCE_TYPES } from '@/config/enums';
import {
  getSubscriptions,
  deleteSubscription,
  getSubscription,
  postSubscription,
  patchSubscription,
  putSubscription,
  getUserSubscriptions,
} from '@/api/repositories/subscriptionRepository';
import { formatDateTime } from '@/shared/utils';
import { ApiClient, Client, User } from '@/entities';
import store from '@/store';
import { DateTime } from 'luxon';
import { UserProfile } from '@/entities/user-profile/UserProfile';
import { SmartSelection } from '@/entities/smart-selection/SmartSelection';
import router from '@/router';

const initialData = () => ({
  client_id: null,
  start_date: null,
  end_date_info: null,
  end_date: null,
  subscription_type_id: null,
  smart_selections: [],
  smart_settings: null,
  is_suspended: false,
  flattened_data: {},
  source_id: null,
  product_id: PRODUCTS.SMART,
  order_id: '',
});

export class Subscription extends BaseEntity {
  static entityKey = 'subscription';
  static loadAllItemsFunc = getSubscriptions;
  static loadItemFunc = getSubscription;
  static loadUserItemsFunc = getUserSubscriptions;
  static deleteItemFunc = deleteSubscription;
  static postItemFunc = postSubscription;
  static patchItemFunc = patchSubscription;
  static putItemFunc = putSubscription;
  static datasetKey = 'subscriptions';
  static routes = {
    list: 'SupportSubscriptions',
    view: 'SupportSubscription',
    edit: 'SupportSubscriptionEdit',
    create: 'SupportSubscriptionCreate',
  };
  static entityTypeId = ENTITY_TYPES.SUBSCRIPTION;

  constructor(...args) {
    super(initialData, ...args);

    this.initCustomName();
    this.selfClass = Subscription;
  }

  getName() {
    return this.name;
  }

  prepareItemData(data, defaultData) {
    const { user_profile, smart_selections = [], api_clients = [], ...restData } = data;

    this.selections = (smart_selections || []).map(
      (selectionData) =>
        new SmartSelection({
          ...selectionData,
          subscription: this,
        }),
    );
    this.userProfile = user_profile ? new UserProfile(user_profile) : null;
    this.client = null;
    this.user = null;
    this.apiClients = (api_clients || []).map((apiClientData) => new ApiClient(apiClientData));

    _.merge(this.data, defaultData(), restData);
  }

  getFormData() {
    const data = super.getFormData();
    const smart_settings = data.smart_settings || {};

    return {
      client_id: data.client_id,
      subscription_type_id: data.subscription_type_id,
      is_suspended: data.is_suspended,
      flattened_data: data.flattened_data,
      source_id: data.source_id,
      product_id: data.product_id,
      order_id: data.order_id || '',
      start_date: data.start_date ? DateTime.fromISO(data.start_date) : null,
      end_date_info: data.end_date_info ? DateTime.fromISO(data.end_date_info) : null,
      end_date: data.end_date ? DateTime.fromISO(data.end_date) : null,
      smart_settings: {
        country_id: smart_settings.country_id,
        project_history_start_date: smart_settings.project_history_start_date
          ? DateTime.fromISO(smart_settings.project_history_start_date)
          : null,
        support_phone: smart_settings.support_phone || '',
        support_email: smart_settings.support_email || '',
        support_name: smart_settings.support_name || '',
        modules: smart_settings.modules || [],
      },
      smart_selections: this.selections.map((selection) => selection.clone()),
    };
  }

  static getAllActions() {
    const allUserProfileActions = UserProfile.getAllActions().map((action) => ({
      ...action,
      type: action.type || 'user_profile',
    }));

    return [
      {
        key: 'view',
        name: Vue.prototype.$vDict('subscriptions.action_view.text'),
        icon: 'pwd-show',
      },
      {
        key: 'edit',
        name: Vue.prototype.$vDict('subscriptions.action_edit.text'),
        icon: 'notebook-pen',
      },
      {
        key: 'create_user',
        icon: 'add-user',
        name: Vue.prototype.$vDict('subscriptions.add_user_button.text'),
      },
      {
        key: 'create_api_client',
        dataCy: 'create-api-client',
        icon: 'api',
        name: Vue.prototype.$vDict('subscriptions.create_api_client_button.text'),
      },
      {
        key: 'view_api_client',
        dataCy: 'view-api-client',
        icon: 'api',
        name: Vue.prototype.$vDict('subscriptions.api_client_button.text'),
      },
      {
        type: 'divider',
      },
      ...allUserProfileActions.filter((action) => ['deactivate', 'activate'].includes(action.key)),
      {
        type: 'divider',
      },
      {
        key: 'delete',
        name: Vue.prototype.$vDict('subscriptions.action_delete.text'),
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  hasEditAction() {
    const sourceTypeId = this.getValue('source_type_id');
    let res = true;

    switch (sourceTypeId) {
      case SOURCE_TYPES.MYRAN_SMART:
      case SOURCE_TYPES.MYRAN_MNB:
      case SOURCE_TYPES.MYRAN_MSB:
      case SOURCE_TYPES.MYRAN_FNL:
      case SOURCE_TYPES.MYRAN_PARABYG:
        res = !!store.getters['Account/hasComponent']('edit_myran_subscription');
        break;
      default:
    }

    return res;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    const userProfileActions = this.userProfile?.getActions() || [];

    return actions.filter((action) => {
      if (action.type === 'user_profile') {
        return userProfileActions.some((userProfileAction) => userProfileAction.key === action.key);
      }

      let res = true;

      switch (action.key) {
        case 'edit':
          res = this.hasEditAction();
          break;
        case 'create_user':
          res = !!this.listInstance && !this.userProfile && !this.isExpired();
          break;
        case 'delete':
          res =
            store.getters['Account/hasComponent']('subscription_delete_button') &&
            this.listInstance?.listTypeId !== LIST_TYPES.USER_SUBSCRIPTIONS;
          break;
        case 'view_api_client':
          res = store.getters['Account/hasComponent']('api_clients') && this.apiClients.length > 0;
          break;
        case 'create_api_client':
          res =
            store.getters['Account/hasComponent']('api_clients') && this.apiClients.length === 0 && !this.isExpired();
          break;
        default:
      }

      return res;
    });
  }

  async doAction(action) {
    if (action.type === 'user_profile') {
      await this.userProfile?.doAction(action);
      return;
    }

    switch (action.key) {
      case 'view_api_client':
        this.goToApiClients();
        break;
      case 'create_api_client':
        router.push({
          name: ApiClient.routes.create,
          query: {
            subscription_id: this.id,
            client_id: this.getValue('client_id'),
          },
        });
        break;
      default:
        await super.doAction(action);
    }
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'end_date':
      case 'modified_at':
      case 'end_date_info':
      case 'start_date':
      case 'created_at':
      case 'smart_settings.project_history_start_date':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE, userZone: true }) : '';
        break;
      case 'smart_settings.country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      case 'status':
        res = this.getSubscriptionStatus();
        break;
      case 'source_type_id':
        value = this.getSourceType();
        res = value?.name || '';
        break;
      case 'subscription_type_id':
        value = this.getSubscriptionType();
        res = value?.name || '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'client_id':
        res = this.getClient(dataset);
        break;
      case 'status':
        res = this.getSubscriptionStatus();
        break;
      default:
    }

    return res;
  }

  getSubscriptionType() {
    return this.getItemFromEnum('SUBSCRIPTION_TYPES', this.getValue('subscription_type_id'));
  }

  isExpired() {
    const expiredDate = this.getValue('end_date');
    return DateTime.fromISO(expiredDate) < DateTime.now();
  }

  isActive() {
    const isExpired = this.isExpired();
    return !isExpired && !this.getValue('is_suspended');
  }

  isActiveOnUser() {
    return this.isActive() && this.userProfile?.isActive();
  }

  getSubscriptionStatus() {
    let res;

    if (this.isExpired()) {
      res = {
        variant: 'expired',
        name: Vue.prototype.$vDict('subscriptions.status_expired.text'),
      };
    } else if (this.userProfile) {
      res = this.userProfile.getStatus();
    } else {
      res = {
        variant: 'active',
        name: Vue.prototype.$vDict('subscriptions.status_live.text'),
      };
    }

    return res;
  }

  getClient(dataset) {
    const clientId = this.getValue('client_id');
    return this.getDatasetItem(dataset, 'clients', clientId);
  }

  async fetchClient() {
    const clientId = this.getValue('client_id');

    if (!clientId) {
      return;
    }

    try {
      const response = await Client.loadItemFunc(clientId, {
        id_type: 'sharp',
        subscriptions: 0,
      });
      const clientData = response?.data || {};

      this.client = new Client(clientData);
    } catch (error) {}
  }

  goToApiClients() {
    if (!this.apiClients.length) {
      return;
    }

    if (this.apiClients.length > 1) {
      router.push({
        name: ApiClient.routes.list,
        query: {
          searchFilter: `subscription_id:[${this.id}]`,
        },
      });
      return;
    }

    router.push({
      name: ApiClient.routes.view,
      params: {
        id: this.apiClients[0].id,
      },
    });
  }

  async fetchApiClients() {
    const query = {
      limit: 10,
      filter: {
        subscription_id: {
          in: [this.id],
        },
      },
    };
    let response;

    try {
      response = await ApiClient.loadAllItemsFunc(query);
      this.apiClients = (response?.data || []).map((apiClientData) => new ApiClient(apiClientData));
    } catch (e) {}
  }

  getPutData(formData = {}) {
    return {
      ...formData,
      smart_selections: formData.smart_selections.map((selection) => selection.getData()),
    };
  }

  getPostData(formData = {}) {
    return {
      ...formData,
      smart_selections: formData.smart_selections.map((selection) => selection.getData()),
    };
  }

  async fetchUserProfile(userId) {
    const query = {
      filter: {
        'user.id': {
          in: [userId],
        },
        subscription_id: {
          in: [this.id],
        },
      },
      smart_selections: 0,
      limit: process.env.VUE_APP_MAX_LIMIT,
    };

    try {
      const response = await UserProfile.loadAllItemsFunc(query);
      const userProfiles = response?.data || [];
      const userProfileData = userProfiles.find((el) => el.subscription_id === this.id);

      if (userProfileData) {
        this.userProfile = new UserProfile(userProfileData);
      }
    } catch (e) {}
  }
}
