/*
  Company class
 */

import Vue from 'vue';
import CRMEntity from '@/shared/classes/entity/CRMEntity';
import {
  USER_COMPANY_FOLDERS,
  TRIGGER_TYPES,
  CLIENT_COMPANY_FOLDER_TYPES,
  ENTITY_TYPES,
  CLIENT_COMPANY_FOLDERS,
  COMMUNICATION_TYPES,
  ADDRESS_TYPES,
  COUNTRIES,
  LIST_TYPES,
} from '@/config/enums';
import vars from '@/config/vars';
import { formatDateTime, formatDateTimeNoWrap, roundNumber } from '@/shared/utils';
import { formatNumber, formatOrgNumber } from '@/shared/formatters';
import { getCompanies, getCompany } from '@/api/repositories/leadsRepository';
import {
  getCompanies as getUserCompanies,
  putCompany,
  putCompanyView,
  putBulkCompanies,
  deleteCompanies,
} from '@/api/repositories/salesRepository';
import { fieldsBlackList } from '@/entities/company/fields';
import store from '@/store';

const FieldCompanyTitle = () => import('@/entities/company/views/FieldCompanyTitle.vue');

const { DateTime } = require('luxon');

export class BaseCompany extends CRMEntity {
  static entityKey = 'company';
  static userSectionAccessComponent = 'user_companies';
  static userSectionListTypeId = LIST_TYPES.USER_COMPANIES;
  static statusKey = 'client_company_folder_id';
  static statusFilterKey = 'users.client_company_folder_id';
  static idsKey = 'company_ids';
  static fieldsBlackList = fieldsBlackList();
  static nameFieldKey = 'name';
  static datasetKey = 'companies';
  static statusNewId = USER_COMPANY_FOLDERS.PROSPECTS;
  static smallIcon = 'company-sm';
  static triggerTypesForTags = [TRIGGER_TYPES.COMPANY, TRIGGER_TYPES.PROJECT];
  static hasContentStats = true;
  static entityTypeId = ENTITY_TYPES.COMPANY;
  static titleComponent = FieldCompanyTitle;

  // routes
  static allSectionRoutes = {
    view: 'Company',
    list: 'Companies',
  };
  static userSectionRoutes = {
    view: 'MyCompany',
    list: 'MyCompanies',
  };

  // api methods
  static loadItemFunc = getCompany;
  static loadAllItemsFunc = getCompanies;
  static loadUserItemsFunc = getUserCompanies;
  static updateStatusFunc = putCompany;
  static putViewedFunc = putCompanyView;
  static putItemsFunc = putBulkCompanies;
  static deleteItemsFunc = deleteCompanies;

  static getEntityTypeText() {
    return Vue.prototype.$vDict(`companies.entity_type.text`);
  }

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict(`companies.entity_plural_type.text`);
  }

  static prepareCompanyRoles(companyRoles = []) {
    const roles = [];

    companyRoles.forEach((role) => {
      const lRole = Vue.prototype.$lFind('client.company_roles', { id: role.company_role_id });
      const roleDate = role.late_role ? role.created_at : role.appointed_at;

      if (lRole) {
        roles.push({
          ...role,
          company_role_name: lRole.name,
          company_role_icon: lRole.icon,
          company_role_main_date: roleDate
            ? formatDateTime(roleDate, {
                toFormat: vars.LUXON_FORMAT_SHORT_DATE,
              })
            : '',
        });
      }
    });

    return roles;
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.isFictitious = vars.FICTITIOUS_COMPANY_TYPES.includes(this.data.company_type_id);
    this.selfClass = BaseCompany;
  }

  static getMapParams() {
    const entityType = this.getEntityPluralTypeText();

    return {
      categories: Vue.prototype.$lDict('client.company_types'),
      defaultCategory: {
        name: Vue.prototype.$vDict('global.map_other_entities.text', {
          entityType,
        }),
        icon: 'other-companies',
      },
      groupCategory: {
        name: Vue.prototype.$vDict('global.map_group_entities.text', {
          entityType,
        }),
        icon: 'company-group',
      },
    };
  }

  getMapData() {
    return {
      id: this.getValue('id'),
      title: this.getName(),
      location: this.getValue('location'),
      category_id: this.getValue('company_type_id'),
      _cluster: this.getValue('_cluster'),
    };
  }

  static getStatuses(_options = {}) {
    const options = {
      showSetStatus: false,
      showAllStatus: false,
      ..._options,
    };
    const userFolders = store.getters['Account/getSettingsValue']('client.company_folders') || [];
    const folders = [
      {
        id: -1,
        name: 'Prospects',
        isSystem: true,
      },
      ...userFolders,
    ];

    if (options.showAllStatus) {
      folders.unshift({
        id: 'all',
        key: 'all',
        name: Vue.prototype.$vDict('enums.user_company_status_all.text'),
        icon: 'baseline-inbox',
      });
    }

    if (options.showSetStatus) {
      folders.unshift({
        id: null,
        name: Vue.prototype.$vDict('companies.set_folder.text'),
        key: 'all',
        icon: 'company-add-folder',
      });
    }

    return folders.map((folder) => {
      let folderKey = folder.key;
      let folderName = folder.name;
      let propsKey = folderKey;

      if (!folderKey) {
        folderKey = folder.name.toLowerCase();

        if (folder.client_company_folder_type_id === CLIENT_COMPANY_FOLDER_TYPES.CUSTOM) {
          propsKey = 'custom';
        } else {
          propsKey = folderKey;
          folderName = Vue.prototype.$vDict(`enums.user_company_status_${folderKey}.text`);
        }
      }

      const properties = CLIENT_COMPANY_FOLDERS.properties[propsKey] || {};

      return {
        ...folder,
        ...properties,
        key: folderKey,
        name: folderName,
      };
    });
  }

  static getUnknownStatus() {
    return {
      boldIcon: 'folder-unknown-bold',
      coworkerIcon: 'folder-unknown',
      name: Vue.prototype.$vDict('enums.user_company_status_unknown.text'),
    };
  }

  static getNotAvailableText() {
    return Vue.prototype.$vDict('companies.company_not_available.text');
  }

  getName() {
    return _.compact([this.data.name, this.data.name2]).join(', ');
  }

  getTypeName() {
    return Vue.prototype.$vDict('company.company_type_text.text');
  }

  getFieldValue(fieldKey, listTypeId = null, options = {}) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;
    let tag;

    switch (fieldKey) {
      case 'phones':
        value = this.getCommunicationsInfo([COMMUNICATION_TYPES.PHONE, COMMUNICATION_TYPES.MOBILE]);
        res = value.length > 0 ? value : null;
        break;
      case 'fax':
        value = this.getCommunicationsInfo([COMMUNICATION_TYPES.FAX]);
        res = value.length > 0 ? value : null;
        break;
      case 'email':
        value = this.getCommunicationsInfo([COMMUNICATION_TYPES.EMAIL]);
        res = value.length > 0 ? value.map((el) => el.value) : null;
        break;
      case 'website':
        res = this.getWebsiteLink();
        break;
      case 'visiting_postcode':
        res = this.getAddresses(ADDRESS_TYPES.VISITING_ADDRESS, ['city', 'postcode']).join(', ');
        break;
      case 'visiting_street':
        res = this.getAddresses(ADDRESS_TYPES.VISITING_ADDRESS, ['street', 'street_number']).join(' ');
        break;
      case 'post_city':
        res = this.getAddresses(ADDRESS_TYPES.MAILING_ADDRESS, ['city', 'postcode']).join(', ');
        break;
      case 'post_street':
        res = this.getAddresses(ADDRESS_TYPES.MAILING_ADDRESS, ['street', 'street_number']).join(' ');
        break;
      case 'project_value_range':
        res = this.getFormattedBudgetValue(options.showCurrency) || '—';
        break;
      case 'project_count':
        value = this.data.projects_count || [];
        res = formatNumber(value[0]);
        break;
      case 'latest_update_at':
      case 'delivered_at':
        res = formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE, utc: true });
        break;
      case 'project_company_roles':
        res = this.getProjectCompanyRoles();
        break;
      case 'addresses':
        res = this.getFieldAddresses();
        break;
      case 'org_nr':
        res = this.getOrgNumber();
        break;
      case 'company_type_id':
        res = this.getCompanyType('name');
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;
    let value;

    switch (fieldKey) {
      case 'tags':
        res = this.getFieldTags(dataset);
        break;
      case 'visiting_region_id':
        res = this.getAddressesValue(ADDRESS_TYPES.VISITING_ADDRESS, 'region_id', dataset);
        break;
      case 'visiting_district_id':
        res = this.getAddressesValue(ADDRESS_TYPES.VISITING_ADDRESS, 'district_id', dataset);
        break;
      case 'post_region_id':
        res = this.getAddressesValue(ADDRESS_TYPES.MAILING_ADDRESS, 'region_id', dataset);
        break;
      case 'post_district_id':
        res = this.getAddressesValue(ADDRESS_TYPES.MAILING_ADDRESS, 'district_id', dataset);
        break;
      case 'client_statuses':
        value = this.getClientStatuses(dataset);
        res = value.length > 0 ? value : null;
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getFieldAddresses() {
    const streets = this.getValues(['street', 'street_number']).join(' ');
    const cities = this.getValues(['postcode', 'city']).join(' ');

    return _.compact([streets, cities]);
  }

  getProjectCompanyRoles() {
    const projectCompanyRoles = this.getValue('project_company_roles', []);
    return this.constructor.prepareCompanyRoles(projectCompanyRoles);
  }

  getAddresses(typeId, keys) {
    const addresses = this.data.addresses || [];
    const res = [];

    addresses.forEach((item) => {
      if (item.address_type_id === typeId) {
        keys.forEach((key) => {
          if (item[key]) {
            let value = item[key];

            if (key === 'postcode') {
              value = this.getPostcodeTextFromValue(value);
            }

            res.push(value);
          }
        });
      }
    });

    return res;
  }

  getAddressesValue(typeId, key, dataset) {
    const addresses = this.data.addresses || [];
    const address = addresses.find((el) => el.address_type_id === typeId);

    return address ? this.getAdministrativeUnit(address[key], key, dataset) : null;
  }

  getOrgNumber() {
    return this.data.org_nr ? formatOrgNumber(this.data.org_nr, this.data.country_id) : null;
  }

  getWebsiteLink() {
    const websites = this.getCommunicationsInfo([COMMUNICATION_TYPES.WEBSITE]);
    return websites.length > 0 ? websites[0].value : null;
  }

  setContacts(contacts) {
    this.data.contacts = _.cloneDeep(contacts);
  }

  getAddress(addressTypeId) {
    const addresses = this.getValue('addresses', []);
    const address = addresses.find((el) => el.address_type_id === addressTypeId);
    let res = null;

    if (address) {
      const street = _.compact([address.street, address.street_number]).join(' ');
      const city = _.compact([address.postcode, address.city]).join(' ');

      res = {
        text: _.compact([street, city]).join(', '),
        location: address.location,
      };
    }

    return res;
  }

  getCompanyType(prop) {
    return Vue.prototype.$lFind('client.company_types', {
      id: this.data.company_type_id,
      prop,
    });
  }

  getMoreInfoLink() {
    let res = null;

    if (_.has(this.data, 'org_nr') && _.has(this.data, 'country_id')) {
      switch (this.data.country_id) {
        case COUNTRIES.SWEDEN:
          res = `http://www.allabolag.se/${this.data.org_nr}`;
          break;
        case COUNTRIES.NORWAY:
          res = `http://www.proff.no/selskap/-/-/-/${this.data.org_nr}`;
          break;
        default:
      }
    }

    return res;
  }

  getBudgetValue() {
    const budgetValue = this.getValue('projects_value_sum[0]');
    return roundNumber(budgetValue);
  }
}
