/*
  File class
 */

import Vue from 'vue';
import store from '@/store';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES, FILE_STATUSES, LIST_TYPES } from '@/config/enums';
import { deleteFile, downloadFile, getFiles } from '@/api/repositories/filesRepository';
import { formatDateTime } from '@/shared/utils';
import { getFileDownloadLink } from '@/shared/api';
import { getEntityListConfigs } from '@/entities';

export class File extends BaseEntity {
  static entityKey = 'file';
  static userSectionAccessComponent = 'user_files';
  static userSectionListTypeId = LIST_TYPES.USER_FILES;
  static datasetKey = 'files';
  static entityTypeId = ENTITY_TYPES.FILE;
  // api
  static loadAllItemsFunc = getFiles;
  static deleteItemFunc = deleteFile;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('user_files.plural_files_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = File;
  }

  static getAllActions() {
    return [
      {
        key: 'download',
        dataCy: 'download',
        icon: 'download',
        getLink: (item) => {
          return getFileDownloadLink(item.id, 'files');
        },
        linkAttrs: {
          download: '',
        },
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  static getListTypes() {
    const elConfigs = getEntityListConfigs();
    const listTypes = _.invert(LIST_TYPES);
    const res = [];

    Object.entries(elConfigs).forEach(([id, config]) => {
      if (config?.sharedOptions?.exportToFile) {
        const key = listTypes[id];

        res.push({
          id: Number(id),
          name: Vue.prototype.$vDict(`enums.list_type_${key.toLowerCase()}.text`),
          hasAccess: config.hasAccess,
        });
      }
    });

    return res;
  }

  static getFileStatuses() {
    return Vue.prototype.$getEnumsList('FILE_STATUSES');
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue
          ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true })
          : '';
        break;
      case 'list_type_id':
        value = this.getListType();
        res = value.name;
        break;
      case 'file_status':
        res = this.getStatus();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'user_id':
        res = this.getUser(dataset);
        break;
      case 'client_id':
        res = this.getClient(dataset);
        break;
      default:
    }

    return res;
  }

  getClient(dataset) {
    const clientId = this.getValue('client_id');
    return this.getDatasetItem(dataset, 'clients', clientId);
  }

  getStatus() {
    const statuses = this.selfClass.getFileStatuses();
    return statuses.find((status) => status.id === this.getValue('file_status'));
  }

  async downloadFile(item) {
    let response = null;

    try {
      response = await downloadFile(item.id);

      if (response) {
        window.location.href = response;
      }
    } catch (error) {
      console.log(error);
    }

    return Promise.resolve(response);
  }

  isAllowDelete() {
    const isCurrentUser = this.getValue('user_id') === store.getters['Account/userId'];
    const allowedStatuses = [FILE_STATUSES.GENERATED, FILE_STATUSES.FAILED];
    const fileStatus = this.getValue('file_status');

    return allowedStatuses.includes(fileStatus) && isCurrentUser;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter((action) => {
      let res = true;

      switch (action.key) {
        case 'download':
          res = this.getValue('file_status') === FILE_STATUSES.GENERATED;
          break;
        case 'delete':
          res = this.isAllowDelete();
          break;
        default:
      }

      return res;
    });
  }

  async doAction(action) {
    switch (action.key) {
      case 'download':
        await this.downloadFile();
        break;
      default:
        await super.doAction(action);
    }
  }
}
