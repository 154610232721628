/*
  ProjectsList class
 */

import Vue from 'vue';
import CRMEntityList from '@/shared/classes/list/CRMEntityList';
import { USER_PROJECT_STATUSES, LIST_TYPES } from '@/config/enums';
import store from '@/store';
import { useFieldsSettings } from '@/entities/project/fields/useFieldsSettings';
import { printSettingsFields, fixedPrintFields } from '@/entities/project/fields';
import { getItemsId } from '@/shared/utils';
import { getProjectsListsConfig } from '@/entities/project/config';
import { Company, Contact } from '@/entities';
import { BaseProject } from '@/entities/project/BaseProject';
import { inheritFields, useExportProjectsFields } from '@/entities/project/export-to-file/useExportProjectsFields';

const ProjectsTableView = () => import('./views/ProjectsTableView');
const ProjectCardView = () => import('./views/ProjectCardView');
const ProjectsMap = () => import('./views/ProjectsMap');
const FieldsPicker = () => import('./views/ProjectsFieldsPicker.vue');

const sortLookups = () => [
  { sortKey: 'stage_id', lookupKey: 'client.project_stages', dynamicValuesBefore: [-1], dynamicValuesAfter: [0] },
  { sortKey: 'ecocertified_type_id', lookupKey: 'client.ecocertified_types' },
  { sortKey: 'uncertainty_id', lookupKey: 'client.uncertainties' },
  { sortKey: 'contract_type_id', lookupKey: 'client.contract_types' },
  { sortKey: 'certification_types', lookupKey: 'client.certification_types' },
  { sortKey: 'planning_stage_id', lookupKey: 'client.planning_stages' },
];

export class BaseProjectsList extends CRMEntityList {
  static urlStatusKey = 'status';
  static statusIdWithNewItems = USER_PROJECT_STATUSES.NEW;
  static entityClass = BaseProject;
  static isStaticNavigation = true;

  constructor(listTypeId) {
    super(listTypeId, getProjectsListsConfig);

    this.filterKeysWithScore = ['keywords'];
    this.mapFields = ['id', 'title', 'category_id', 'location', 'deleted'];

    // variables
    this.sortLookups = sortLookups();
    this.triggerListTypeId = LIST_TYPES.PROJECT_TRIGGER;

    // for print
    this.printAdditionalFieldset = ['cat_dev_type'];
    this.printFieldsBlacklist = ['name', 'status', 'notes', 'tags', 'email'];

    // view components
    this.tableView = ProjectsTableView;
    this.cardView = ProjectCardView;
    this.mapView = ProjectsMap;
    this.fieldsPicker = FieldsPicker;

    //
    this.selfClass = BaseProjectsList;

    // fields
    const { getRequiredFields, getFieldsSections } = useFieldsSettings();

    this.getPrintSettingsFields = printSettingsFields;
    this.getFixedPrintFields = fixedPrintFields;
    this.getRequiredFields = getRequiredFields;
    this.getFieldsSections = getFieldsSections;
  }

  useExportToFileSettings() {
    return {
      fieldsSettingsComponent: () => import('@/entities/project/export-to-file/ExportProjectsFieldsSettings.vue'),
      useFieldsSections: () => {
        const sections = useExportProjectsFields();
        return this.includeCustomFields(sections);
      },
      inheritFields,
    };
  }

  getSubtitleForClearFolderForm() {
    return Vue.prototype.$vDict('entity_list.clear_folder_projects_subtitle.text');
  }

  getWaitForItemsText() {
    return Vue.prototype.$vDict('projects.projects_in_way.text');
  }

  getEmptyListTitle() {
    return this.isFiltersFilled
      ? Vue.prototype.$vDict('entity_list.projects_list_empty_by_search.text')
      : Vue.prototype.$vDict('entity_list.projects_list_empty.text');
  }

  getContentStats() {
    return store.getters['Account/getUserContentStats']('user_project');
  }

  loadDatasetByQueryFields(items = [], queryFields = []) {
    const ids = {
      regions: queryFields.includes('region_id') ? getItemsId(items, 'region_id') : [],
      districts: queryFields.includes('district_id') ? getItemsId(items, 'district_id') : [],
    };

    this.loadGeographyDataset(ids);

    if (queryFields.includes('companies')) {
      this.loadDatasetEntities(Company, {
        ids: items.reduce((acum, item) => _.union(acum, item.companies), []),
        fields: ['id', 'name'],
      });
    }

    if (queryFields.includes('contacts')) {
      this.loadDatasetEntities(Contact, {
        ids: items.reduce((acum, item) => _.union(acum, item.contacts), []),
        fields: ['id', 'first_name', 'last_name', 'communications'],
      });
    }
  }
}
