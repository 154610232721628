import Vue from "vue";
import { FIELD_VALUE_STYLES, LIST_TYPES } from "@/config/enums";

export const useProjectFields = () => {
  return {
    country_id: {
      key: 'country_id',
      label: Vue.prototype.$vDict('projects.field_country_id.text'),
      fieldset: ['country_id'],
      dataCy: 'country',
    },
    region_id: {
      key: 'region_id',
      label: Vue.prototype.$vDict('projects.field_region_id.text'),
      fieldset: ['region_id'],
      dataCy: 'region',
    },
    district_id: {
      key: 'district_id',
      label: Vue.prototype.$vDict('projects.field_district_id.text'),
      fieldset: ['district_id'],
      dataCy: 'district',
    },
    city: {
      key: 'city',
      label: Vue.prototype.$vDict('projects.field_city.text'),
      fieldset: ['city'],
      sortable: true,
      sortKeys: ['city'],
      dataCy: 'city',
    },
    postcode: {
      key: 'postcode',
      label: Vue.prototype.$vDict('projects.field_postcode.text'),
      fieldset: ['postcode', 'postcode_text'],
      sortable: true,
      sortKeys: ['postcode'],
      dataCy: 'postcode',
    },
    street: {
      key: 'street',
      label: Vue.prototype.$vDict('projects.field_street.text'),
      fieldset: ['street', 'street_number'],
      sortable: true,
      sortKeys: ['street', 'street_number'],
      dataCy: 'street',
    },
    property_designation: {
      key: 'property_designation',
      label: Vue.prototype.$vDict('projects.field_property_designation.text'),
      fieldset: ['property_designation'],
      dataCy: 'property-designation',
    },
    value: {
      key: 'value',
      label: Vue.prototype.$vDict('projects.field_value.text'),
      fieldset: ['value', 'value_unoff', 'original_currency_id'],
      sortable: true,
      sortKeys: ['value'],
      dataCy: 'value',
      valueStyle: FIELD_VALUE_STYLES.CURRENCY,
      sourceKey: 'value',
    },
    contract_type_id: {
      key: 'contract_type_id',
      label: Vue.prototype.$vDict('projects.field_contract_type_id.text'),
      fieldset: ['contract_type_id'],
      sortable: true,
      sortKeys: ['contract_type_id'],
      dataCy: 'contract-type',
    },
    residence_forms: {
      key: 'residence_forms',
      label: Vue.prototype.$vDict('projects.field_residence_forms.text'),
      fieldset: ['residence_forms'],
      dataCy: 'residence-forms',
    },
    ecocertified_type_id: {
      key: 'ecocertified_type_id',
      label: Vue.prototype.$vDict('projects.field_ecocertified_type_id.text'),
      fieldset: ['ecocertified_type_id', 'certification_types'],
      class: 'column-icon',
      sortable: true,
      sortKeys: ['ecocertified_type_id'],
      dataCy: 'project-attribute',
      // hideOnCard: true,
    },
    uncertainty_id: {
      key: 'uncertainty_id',
      label: Vue.prototype.$vDict('projects.field_uncertainty_id.text'),
      fieldset: ['uncertainty_id'],
      dataCy: 'uncertainty',
    },
    building_purpose: {
      key: 'building_purpose',
      label: Vue.prototype.$vDict('projects.field_building_purpose.text'),
      fieldset: ['building_purpose'],
      dataCy: 'building-purpose',
    },
    url: {
      key: 'url',
      label: Vue.prototype.$vDict('projects.field_url.text'),
      fieldset: ['url'],
      dataCy: 'url',
    },
    project_area: {
      key: 'project_area',
      label: Vue.prototype.$vDict('projects.field_project_area.text'),
      fieldset: ['project_area'],
      dataCy: 'project-area',
    },
    start_date: {
      key: 'start_date',
      label: Vue.prototype.$vDict('projects.field_start_date.text'),
      fieldset: ['start_text', 'start_date', 'start_unoff'],
      class: 'column-date',
      sortable: true,
      sortKeys: ['start_date'],
      dataCy: 'start-date',
      sourceKey: 'start_date',
    },
    finish_date: {
      key: 'finish_date',
      label: Vue.prototype.$vDict('projects.field_finish_date.text'),
      fieldset: ['finish_text', 'finish_date'],
      class: 'column-date',
      sortable: true,
      sortKeys: ['finish_date'],
      dataCy: 'finish-date',
      sourceKey: 'finish_date',
    },
    planning_approval_date: {
      key: 'planning_approval_date',
      label: Vue.prototype.$vDict('projects.field_planning_approval_date.text'),
      fieldset: ['planning_approval_date'],
      class: 'column-date',
      sortable: true,
      sortKeys: ['planning_approval_date'],
      dataCy: 'planning-approval-date',
      sourceKey: 'planning_approval_date',
    },
    stage_id: {
      key: 'stage_id',
      label: Vue.prototype.$vDict('projects.field_stage_id.text'),
      fieldset: ['stage_id', 'start_date', 'finish_date', 'companies'],
      class: 'column-icon',
      sortable: true,
      sortKeys: ['stage_id'],
      dataCy: 'stage',
      // hideOnCard: true,
    },
    planning_stage_id: {
      key: 'planning_stage_id',
      label: Vue.prototype.$vDict('projects.field_planning_stage_id.text'),
      fieldset: ['planning_stage_id'],
      sortable: true,
      sortKeys: ['planning_stage_id'],
      dataCy: 'planning-stage',
    },
    buildings: {
      key: 'buildings',
      label: Vue.prototype.$vDict('projects.field_buildings.text'),
      fieldset: ['buildings'],
      sortable: true,
      sortKeys: ['buildings'],
      dataCy: 'buildings',
    },
    apartments: {
      key: 'apartments',
      label: Vue.prototype.$vDict('projects.field_apartments.text'),
      fieldset: ['apartments'],
      sortable: true,
      sortKeys: ['apartments'],
      dataCy: 'apartments',
    },
    floors: {
      key: 'floors',
      label: Vue.prototype.$vDict('projects.field_floors.text'),
      fieldset: ['floors'],
      sortable: true,
      sortKeys: ['floors'],
      dataCy: 'floors',
    },
    underground_floors: {
      key: 'underground_floors',
      label: Vue.prototype.$vDict('projects.field_underground_floors.text'),
      fieldset: ['underground_floors'],
      sortable: true,
      sortKeys: ['underground_floors'],
      dataCy: 'underground-floors',
    },
    parking_lots: {
      key: 'parking_lots',
      label: Vue.prototype.$vDict('projects.field_parking_lots.text'),
      fieldset: ['parking_lots'],
      sortKeys: ['parking_lots'],
      dataCy: 'parking-lots',
    },
    materials: {
      key: 'materials',
      label: Vue.prototype.$vDict('projects.field_materials.text'),
      fieldset: ['materials', 'material_data'],
      dataCy: 'materials',
    },
    gross_area: {
      key: 'gross_area',
      label: Vue.prototype.$vDict('projects.field_gross_area.text'),
      fieldset: ['gross_area'],
      sortable: true,
      sortKeys: ['gross_area'],
      dataCy: 'gross-area',
    },
    gross_volume: {
      key: 'gross_volume',
      label: Vue.prototype.$vDict('projects.field_gross_volume.text'),
      fieldset: ['gross_volume'],
      sortable: true,
      sortKeys: ['gross_volume'],
      dataCy: 'gross-volume',
    },
    usage_area: {
      key: 'usage_area',
      label: Vue.prototype.$vDict('projects.field_usage_area.text'),
      fieldset: ['usage_area'],
      sortable: true,
      sortKeys: ['usage_area'],
      dataCy: 'usage-area',
    },
    construction_area: {
      key: 'construction_area',
      label: Vue.prototype.$vDict('projects.field_construction_area.text'),
      fieldset: ['construction_area'],
      sortable: true,
      sortKeys: ['construction_area'],
      dataCy: 'construction-area',
    },
    renovation_area: {
      key: 'renovation_area',
      label: Vue.prototype.$vDict('projects.field_renovation_area.text'),
      fieldset: ['renovation_area'],
      sortable: true,
      sortKeys: ['renovation_area'],
      dataCy: 'renovation-area',
    },
    demolition_area: {
      key: 'demolition_area',
      label: Vue.prototype.$vDict('projects.field_demolition_area.text'),
      fieldset: ['demolition_area'],
      sortable: true,
      sortKeys: ['demolition_area'],
      dataCy: 'demolition-area',
    },
    decontamination_area: {
      key: 'decontamination_area',
      label: Vue.prototype.$vDict('projects.field_decontamination_area.text'),
      fieldset: ['decontamination_area'],
      sortable: true,
      sortKeys: ['decontamination_area'],
      dataCy: 'decontamination-area',
    },
    latest_update_at: {
      key: 'latest_update_at',
      label: Vue.prototype.$vDict('projects.field_latest_update_at.text'),
      fieldset: ['latest_update_at'],
      sortable: true,
      sortKeys: ['latest_update_at'],
      dataCy: 'latest-update-at',
      sourceKey: 'latest_update_at',
    },
    delivered_at: {
      key: 'delivered_at',
      label: Vue.prototype.$vDict('projects.field_delivered_at.text'),
      fieldset: ['delivered_at'],
      sortable: (statusId) => !!statusId && statusId !== 'all',
      sortKeys: ['delivered_at'],
      listTypeIds: [LIST_TYPES.USER_PROJECTS],
      dataCy: 'delivered-at',
      sourceKey: 'delivered_at',
    },
    responsible: {
      key: 'responsible',
      label: Vue.prototype.$vDict('projects.field_responsible.text'),
      fieldset: [],
      dataCy: 'responsible',
    },
    client_statuses: {
      key: 'client_statuses',
      label: Vue.prototype.$vDict('projects.field_client_statuses.text'),
      fieldset: ['users'],
      listTypeIds: [LIST_TYPES.USER_PROJECTS],
      dataCy: 'client-statuses',
    },
    project_nr: {
      key: 'project_nr',
      label: Vue.prototype.$vDict('projects.field_project_nr.text'),
      fieldset: ['source_id'],
      dataCy: 'project-nr',
    },
    project_title: {
      key: 'project_title',
      label: Vue.prototype.$vDict('projects.field_subtitle.text'),
      fieldset: ['title'],
      dataCy: 'project-title',
    },
    project_name: {
      key: 'project_name',
      label: Vue.prototype.$vDict('projects.field_title.text'),
      fieldset: ['subtitle'],
      dataCy: 'project-name',
    },
  };
};
