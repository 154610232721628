/* eslint-disable import/prefer-default-export */
/*
  DashboardWidget class
*/

import Vue from 'vue';

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  getCountryDefaultDashboardWidgets,
  putCountryDefaultDashboardWidgets,
} from "@/api/repositories/dashboardRepository";

export class DefaultDashboardWidgets extends BaseEntity {
  static entityKey = 'default_dashboard_widgets';
  static datasetKey = 'defaultDashboardWidgets';
  static entityTypeId = ENTITY_TYPES;
  static loadAllItemsFunc = async (data) => {
    const defaultWidgetsResponse = await getCountryDefaultDashboardWidgets(data);
    defaultWidgetsResponse.data = (defaultWidgetsResponse?.data || []).map((widget) => ({
      ...widget,
      id: widget.country_id,
    }));
    return defaultWidgetsResponse;
  };
  static putItemFunc = putCountryDefaultDashboardWidgets;
  static routes = {
    edit: 'DefaultDashboardWidgetsEdit',
    list: 'DefaultDashboardWidgets',
    create: 'DefaultDashboardWidgetsNew',
  };

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('default_dashboard_widgets.plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = DefaultDashboardWidgets;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        icon: 'edit',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'id':
        res = this.getValue('country_id');
        break;
      case 'country_id':
        res = this.getCountryFieldValue(fieldValue);
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'widgets':
        res = this.getWidgets(dataset);
        break;
      default:
    }

    return res;
  }

  getWidgets(dataset = {}) {
    const { allWidgets, countryComponents } = dataset;

    if (!allWidgets || !countryComponents) {
      return null;
    }

    const countryId = this.getValue('country_id');
    const widgets = this.getValue('default_dashboard_widgets', []);
    const components = countryComponents.findById(countryId)?.components || [];
    const widgetTypeIds = widgets.map(widget => widget.dashboard_widget_type_id);
    const res = [];

    widgetTypeIds.forEach(typeId => {
      const widget = allWidgets.findById(typeId);

      if (widget && components.includes(widget.accessComponent)) {
        res.push(widget);
      }
    });

    return res;
  }
  
  getCountryFieldValue(fieldValue) {
    const countryField = Vue.prototype.$lFind('global.countries', { id: fieldValue });
    return {
      name: countryField.name,
      countryKey: enums.COUNTRIES.properties[countryField.id].countryKey
    }
  }
}
