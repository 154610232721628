/* eslint-disable import/prefer-default-export */
import BaseEntity from '@/shared/classes/entity/BaseEntity';

import Vue from 'vue';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import {
  deleteDefaultFieldset,
  getDefaultFieldsets,
  patchDefaultFieldset,
  getDefaultFieldset,
  postDefaultFieldset,
} from "@/api/repositories/defaultExportFieldsetsRepository";
import { isJSON } from "@/shared/utils";
import { getEntityListConfigs } from "@/entities";
import { EntityListFactory } from "@/entities/EntityListFactory";
import { getIndexedArray } from '@/shared/proxies';

const FieldsIndexedArray = getIndexedArray('key');
export class DefaultExportFieldset extends BaseEntity {
  static entityKey = 'default_export_fieldset';

  static datasetKey = 'defaultExportFieldsets';

  static entityTypeId = ENTITY_TYPES.DEFAULT_EXPORT_FIELDSET;

  static routes = {
    edit: 'SystemDefaultExportFieldsetsEditor',
    create: 'SystemDefaultExportFieldsetsNew',
    list: 'SystemDefaultExportFieldsets',
  };

  static loadAllItemsFunc = getDefaultFieldsets;
  static loadItemFunc = getDefaultFieldset;
  static patchItemFunc = patchDefaultFieldset;
  static postItemFunc = postDefaultFieldset;
  static deleteItemFunc = deleteDefaultFieldset;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('default_export_fieldsets.plural_default_fieldsets_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = DefaultExportFieldset;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  static getListTypes() {
    const listTypes = Vue.prototype.$getEnumsList('LIST_TYPES');
    const listsConfigs = getEntityListConfigs();

    return listTypes.filter(listType => listsConfigs[listType.id]?.customExportFieldset);
  }

  static getListsFieldsSections(listTypes = []) {
    const res = [];

    listTypes.forEach(listType => {
      const entityList = EntityListFactory.create(listType.id);

      const { useFieldsSections } = entityList.useExportToFileSettings();
      const fieldsSections = useFieldsSections();

      res.push({
        listTypeId: listType.id,
        fieldsSections,
        allFields: this.initAllFields(fieldsSections),
      });
    });

    return res;
  }

  static initAllFields(fieldsSections) {
    const allFields = fieldsSections.reduce((acum, section) => {
      const fields = section.selectable || section.hasDynamicFields ? [section] : section.fields;

      return [
        ...acum,
        ...fields.map(field => ({
          ...field,
          sectionKey: section.key,
        })),
      ];
    }, []);

    return new FieldsIndexedArray(allFields);
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      case 'list_type_id':
        value = this.getItemFromEnum('LIST_TYPES', fieldValue);
        res = value?.name || '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'fieldset':
        res = this.getFieldset(dataset);
        break;
      default:
    }

    return res;
  }

  getFieldset(dataset) {
    if (!dataset.listsFieldsSections) {
      return;
    }

    const listTypeId = this.getValue('list_type_id');
    const listFieldsSections = dataset.listsFieldsSections.findById(listTypeId);

    if (!listFieldsSections) {
      return;
    }

    const fsFields = listFieldsSections.allFields;
    const fieldsetString = this.getValue('fieldset', '');
    const res = [];
    let fieldset = [];

    if (isJSON(fieldsetString)) {
      const parsedData = JSON.parse(fieldsetString);
      fieldset = Array.isArray(parsedData) ? parsedData : [];
    }

    fieldset.forEach((field) => {
      const fieldSettings = fsFields.findById(field.key);

      if (fieldSettings?.label) {
        res.push(fieldSettings);
      }
    });

    return res.length > 0 ? res : null;
  }
}
