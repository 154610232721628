import Vue from 'vue';
import {
  COUNTRIES,
} from "@/config/enums";

export const fieldsBlackList = () => ({
  url: {
    countries: [
      // COUNTRIES.SWEDEN,
      // COUNTRIES.FINLAND,
      // COUNTRIES.NORWAY,
      // COUNTRIES.DENMARK,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  uncertainty_id: {
    countries: [
      COUNTRIES.FINLAND,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  planning_stage_id: {
    countries: [
      COUNTRIES.SWEDEN,
      // COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
    ],
  },
  construction_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  gross_volume: {
    countries: [
      COUNTRIES.DENMARK,
    ],
  },
  renovation_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  demolition_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  decontamination_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  usage_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  residence_forms: {
    countries: [
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  ecocertified_type_id: {
    countries: [
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  parking_lots: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  building_purpose: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
    ],
  },
  // stage_id: {
  //   countries: [
  //     COUNTRIES.CZECH,
  //     COUNTRIES.SLOVAKIA,
  //   ],
  // },
  materials: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.NORWAY,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
    ],
  },
  publish_source_id: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
    ],
  },
  contract_type_id: {
    countries: [
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  underground_floors: {
    countries: [
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  company_roles_org_number: {
    countries: [
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  project_area: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
    ],
  },
  planning_approval_date: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
    ],
  },
});

export const printSettingsFields = () => {
  return [
    {
      key: 'description',
      fieldset: ['description', 'what_happens'],
      label: Vue.prototype.$vDict('projects.field_description.text'),
    },
  ];
};

export const fixedPrintFields = () => {
  return [
    {
      key: 'source_id',
      dataCy: 'project-nr',
      label: Vue.prototype.$vDict('project.info_label_project_nr.text'),
      fieldset: ['source_id'],
    },
  ];
};
