import Vue from "vue";
import { useProjectFields } from "@/entities/project/fields/useProjectFields";
import { LIST_TYPES } from "@/config/enums";

export function useFieldsSettings() {
  const getRequiredFields = () => [
    {
      key: 'status',
      label: Vue.prototype.$vDict('projects.field_status.text'),
      class: 'column-status',
      required: true,
      listTypeIds: [
        LIST_TYPES.ALL_PROJECTS,
        LIST_TYPES.COMPANY_PROJECTS,
        LIST_TYPES.CONTACT_PROJECTS,
      ],
      components: ['user_projects'],
      dataCy: 'status',
    },
    {
      key: 'status',
      label: Vue.prototype.$vDict('projects.field_status.text'),
      class: 'column-status',
      required: true,
      fieldset: ['viewed', 'user_project_status_id'],
      listTypeIds: [LIST_TYPES.USER_PROJECTS],
      components: ['user_projects'],
      dataCy: 'status',
    },
    {
      key: 'notes',
      label: Vue.prototype.$vDict('projects.field_notes.text'),
      class: 'column-min column-notes',
      required: true,
      components: ['project_notes', 'project_activities'],
      dataCy: 'notes',
    },
    {
      key: 'tags',
      label: Vue.prototype.$vDict('projects.field_tags.text'),
      fieldset: ['tags'],
      class: 'column-tags',
      required: true,
      listTypeIds: [LIST_TYPES.USER_PROJECTS],
      dataCy: 'tags',
    },
    {
      key: 'title',
      label: Vue.prototype.$vDict('projects.field_title.text'),
      fieldset: [
        'id',
        'title',
        'subtitle',
        'category_id',
        'source_id',
        'version',
        'framework_agreement',
        'deleted',
      ],
      class: 'column-name',
      sortable: true,
      required: true,
      sortKeys: ['title'],
      dataCy: 'title',
    },
  ];

  const getFieldsSections = () => {
    const projectFields = useProjectFields();

    return [
      {
        key: 'geography',
        label: Vue.prototype.$vDict('projects.section_geography.text'),
        icon: 'card-geography',
        dataCy: 'geography',
        fields: [
          projectFields.country_id,
          projectFields.region_id,
          projectFields.district_id,
          projectFields.city,
          projectFields.postcode,
          projectFields.street,
          projectFields.property_designation,
        ],
      },
      {
        key: 'info',
        label: Vue.prototype.$vDict('projects.section_info.text'),
        icon: 'card-info',
        dataCy: 'info',
        fields: [
          projectFields.value,
          projectFields.contract_type_id,
          projectFields.residence_forms,
          projectFields.ecocertified_type_id,
          projectFields.uncertainty_id,
          projectFields.building_purpose,
          projectFields.url,
          projectFields.project_area,
        ],
      },
      {
        key: 'construction_details',
        label: Vue.prototype.$vDict('projects.section_construction_details.text'),
        icon: 'stage-construction',
        dataCy: 'construction-details',
        fields: [
          projectFields.start_date,
          projectFields.finish_date,
          projectFields.planning_approval_date,
          projectFields.stage_id,
          projectFields.planning_stage_id,
        ],
      },
      {
        key: 'properties',
        label: Vue.prototype.$vDict('projects.section_properties.text'),
        icon: 'properties',
        dataCy: 'properties',
        fields: [
          projectFields.buildings,
          projectFields.apartments,
          projectFields.floors,
          projectFields.underground_floors,
          projectFields.parking_lots,
          projectFields.materials,
        ],
      },
      {
        key: 'measurement',
        label: Vue.prototype.$vDict('projects.section_measurement.text'),
        icon: 'area',
        dataCy: 'measurement',
        fields: [
          projectFields.gross_area,
          projectFields.gross_volume,
          projectFields.usage_area,
          projectFields.construction_area,
          projectFields.renovation_area,
          projectFields.demolition_area,
          projectFields.decontamination_area,
        ],
      },
      {
        key: 'companies_on_project',
        label: Vue.prototype.$vDict('projects.section_companies_on_project.text'),
        icon: 'company16',
        class: 'companies-on-project-wrap',
        fieldset: ['roles'],
        dataCy: 'companies-on-project',
        selectable: true,
        queryParams: (value = {}) => {
          return value.company_roles ? {
            visible_roles: value.company_roles,
          } : {};
        },
        fields: [
          {
            key: 'company_roles_org_number',
            label: Vue.prototype.$vDict('projects.field_company_roles_org_number.text'),
            dataCy: 'company-org-number',
          },
          {
            key: 'company_roles_date',
            label: Vue.prototype.$vDict('projects.field_company_roles_date.text'),
            dataCy: 'company-roles-date',
          },
          {
            key: 'company_roles_contact',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact.text'),
            dataCy: 'company-roles-contact',
          },
          {
            key: 'company_roles_contact_role',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact_role.text'),
            dataCy: 'company-roles-contact-role',
            dependentOn: 'company_roles_contact',
          },
          {
            key: 'company_roles_contact_phone',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact_phone.text'),
            dataCy: 'company-roles-contact-phone',
            dependentOn: 'company_roles_contact',
          },
          {
            key: 'company_roles_contact_email',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact_email.text'),
            dataCy: 'company-roles-contact-email',
            dependentOn: 'company_roles_contact',
          },
        ],
      },
      {
        key: 'project_updates',
        label: Vue.prototype.$vDict('projects.section_project_updates.text'),
        icon: 'arrow-sync',
        dataCy: 'project-updates',
        fields: [
          projectFields.latest_update_at,
          projectFields.delivered_at,
          {
            ...projectFields.responsible,
            fieldset: ['responsible_ids'],
            listTypeIds: [LIST_TYPES.USER_PROJECTS],
          },
          {
            ...projectFields.responsible,
            fieldset: [],
            listTypeIds: [LIST_TYPES.ALL_PROJECTS],
          },
          projectFields.client_statuses,
        ],
      },
      {
        key: 'additional_fields',
        label: Vue.prototype.$vDict('projects.section_additional_fields.text'),
        icon: 'baseline-format_list_view',
        dataCy: 'additional-fields',
        fields: [
          {
            key: 'roles',
            label: Vue.prototype.$vDict('projects.field_roles.text'),
            fieldset: ['roles'],
            listTypeIds: [
              LIST_TYPES.COMPANY_PROJECTS,
              LIST_TYPES.CONTACT_PROJECTS,
            ],
            dataCy: 'roles',
          },
          {
            key: 'contacts',
            label: Vue.prototype.$vDict('projects.field_contacts.text'),
            fieldset: ['contacts'],
            class: 'column-entity',
            listTypeIds: [
              LIST_TYPES.COMPANY_PROJECTS,
            ],
            dataCy: 'contacts',
          },
        ],
      },
      {
        key: 'custom_fields',
        label: Vue.prototype.$vDict('projects.section_custom_fields.text'),
        icon: 'internal',
        dataCy: 'custom-fields',
        fields: [],
      },
    ];
  };

  return {
    getRequiredFields,
    getFieldsSections,
  };
}
