/*
  FilesList class
 */

import EntityList from '@/shared/classes/list/EntityList';
import FilesTableView from '@/entities/file/views/FilesTableView';
import { getFilesListsConfig } from '@/entities/file/config';
import { requiredFields } from '@/entities/file/fields';
import { File } from '@/entities';
import { mergeQueryParams } from '@/shared/utils';

export class FilesList extends EntityList {
  static entityClass = File;

  constructor(listTypeId) {
    super(listTypeId, getFilesListsConfig);

    this.tableView = FilesTableView;

    this.selfClass = FilesList;
    this.getRequiredFields = requiredFields;
    this.excludeListTypes = [];
  }

  getPermanentQueryData() {
    let query = super.getPermanentQueryData();

    if (this.excludeListTypes.length > 0) {
      query = mergeQueryParams(query, {
        filter: {
          list_type_id: {
            not_in: this.excludeListTypes,
          },
        },
      });
    }

    return query;
  }

  getSections() {
    const listTypes = this.constructor.entityClass.getListTypes();
    return listTypes.filter((listType) => !this.excludeListTypes.includes(listType.id));
  }
}
