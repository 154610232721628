/* eslint-disable import/prefer-default-export */

import Vue from "vue";
import { SEARCH_FILTER_TYPES } from "@/config/enums";
import DefaultFieldsetsTableView from "@/entities/default-export-fieldset/views/DefaultExportFieldsetsTableView";
import { getDefaultExportFieldsetsListsConfig } from "@/entities/default-export-fieldset/config";
import { requiredFields } from "@/entities/default-export-fieldset/fields";
import { getIndexedArray, IndexedArray } from "@/shared/proxies";
import { DefaultExportFieldset } from "@/entities";
import EntityList from "@/shared/classes/list/EntityList";

export class DefaultExportFieldsetsList extends EntityList {
  static entityClass = DefaultExportFieldset;

  constructor(listTypeId) {
    super(listTypeId, getDefaultExportFieldsetsListsConfig);

    this.tableView = DefaultFieldsetsTableView;

    this.selfClass = DefaultExportFieldsetsList;
    this.getRequiredFields = requiredFields;
  }

  async loadDataForInitList() {
    const { entityClass } = this.selfClass;
    const ListIndexedArray = getIndexedArray('listTypeId');
    const listTypes = entityClass.getListTypes();
    const listsFieldsSections = entityClass.getListsFieldsSections(listTypes);

    Vue.set(this.dataset, 'listTypes', new IndexedArray(listTypes));
    Vue.set(this.dataset, 'listsFieldsSections', new ListIndexedArray(listsFieldsSections));

    return this.dataset;
  }

  getListFiltersSetting(payload = {}) {
    return [
      {
        key: 'country_id',
        dataCy: 'country',
        name: 'Country',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'list_type_id',
        dataCy: 'list-type',
        name: 'List type',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: payload.listTypes || [],
        },
      },
    ];
  }
}
